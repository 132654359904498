var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.Permissions.tagGlobalEdit)?_c('div',{staticClass:"mr-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"26px","height":"26px"},attrs:{"fab":"","color":"primary"},on:{"click":function($event){_vm.editGlobalTags = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,755892400)},[_c('span',[_vm._v("Edit global tags")])]),_c('v-dialog',{attrs:{"persistent":"","width":"700"},model:{value:(_vm.editGlobalTags),callback:function ($$v) {_vm.editGlobalTags=$$v},expression:"editGlobalTags"}},[_c('v-card',{attrs:{"id":"editPopDialog"}},[_c('v-card-title',{staticClass:"white--text primary"},[_vm._v(" Edit Global Tags ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("General Tag(s)")]),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1.5 mr-3",staticStyle:{"width":"26px","height":"26px"},attrs:{"disabled":!_vm.addTag,"fab":"","color":"red darken-3"},on:{"click":function($event){_vm.updateMergeList({
                                            categoryId: _vm.selectedUploadCategory.id, 
                                            shortName: _vm.selectedUploadCategory.shortName,
                                            categoryName: _vm.selectedUploadCategory.name, 
                                            value: _vm.addTag.value,
                                            tagId: _vm.addTag.id
                                            })
                                            _vm.addTag = null}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,3190745317)},[_c('span',[_vm._v("Remove tag")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1.5 mr-1",staticStyle:{"width":"26px","height":"26px"},attrs:{"disabled":!_vm.addTag,"fab":"","color":"primary"},on:{"click":function($event){_vm.updateAddtags({
                                            categoryId: _vm.selectedUploadCategory.id, 
                                            shortName: _vm.selectedUploadCategory.shortName,
                                            categoryName: _vm.selectedUploadCategory.name, 
                                            value: _vm.addTag.value,
                                            tagId: _vm.addTag.id
                                            })
                                            _vm.addTag = null}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1278194536)},[_c('span',[_vm._v("Add tag")])])],1)])]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.Categories,"item-value":"id","item-text":"name","label":"Tag Category","outlined":"","return-object":""},on:{"change":function($event){_vm.addTag = null}},model:{value:(_vm.selectedUploadCategory),callback:function ($$v) {_vm.selectedUploadCategory=$$v},expression:"selectedUploadCategory"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"disabled":!_vm.selectedUploadCategory,"items":_vm.Values,"item-text":'value',"label":"Add Tag","clearable":"","outlined":""},model:{value:(_vm.addTag),callback:function ($$v) {_vm.addTag=$$v},expression:"addTag"}})],1)],1),_c('v-row',{staticClass:"px-2 mb-2"},[_c('h4',[_vm._v("List of tags to merge: ")]),_c('v-col',{staticClass:"d-flex  align-end flex-wrap fluid ma-0 pa-0",staticStyle:{"width":"100%"},attrs:{"cols":"auto"}},_vm._l((_vm.tagsMerge),function(tag,index){return _c('v-chip',{key:index,staticClass:"mt-1 mb-3 mx-1 text-capitalize",style:('height: 25px; background: none; border: 1px solid '+_vm.addColorToTag(tag))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(tag.value)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s((tag.categoryName)))])])],1)}),1)],1),_c('v-row',{staticClass:"px-2 my-3"},[_c('h4',[_vm._v("List of tags to add: ")]),_c('v-col',{staticClass:"d-flex  align-end flex-wrap fluid ma-0 pa-0",staticStyle:{"width":"100%"},attrs:{"cols":"auto"}},_vm._l((_vm.tagsAdd),function(tag,index){return _c('v-chip',{key:index,staticClass:"mt-1 mb-3 mx-1 text-capitalize",style:('height: 25px; background: none; border: 1px solid '+_vm.addColorToTag(tag))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(tag.value)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s((tag.category)))])])],1)}),1)],1),_c('v-row',{staticClass:"my-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-6",attrs:{"disabled":_vm.addTag !== null,"color":"primary"},on:{"click":_vm.confirmEdit}},[_vm._v(" Confirm ")]),_c('v-btn',{staticClass:"mx-6",attrs:{"color":"red"},on:{"click":function($event){_vm.editGlobalTags=false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }