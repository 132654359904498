var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-10",staticStyle:{"width":"100%","height":"500px"}},[_c('l-map',{ref:"popMapElement",staticStyle:{"z-index":"1"},attrs:{"zoom":_vm.zoomLevel,"center":_vm.centerLocation}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.locationtableData),function(pop,index){return _c('l-marker',{key:index,attrs:{"lat-lng":[pop.media.tags.find(function (x) { return x.shortName === 'latlong'; }).value.split(',')[0], pop.media.tags.find(function (x) { return x.shortName === 'latlong'; }).value.split(',')[1]]}},[_c('l-popup',{attrs:{"lat-lng":[pop.media.tags.find(function (x) { return x.shortName === 'latlong'; }).value.split(',')[0], pop.media.tags.find(function (x) { return x.shortName === 'latlong'; }).value.split(',')[1]]}},[_c('span',{staticClass:"white--text d-flex justify-center"},[_vm._v(_vm._s(pop.popName))]),_c('v-row',{staticClass:"ma-0 pt-1 px-3",style:({ minHeight: 'calc(100% - 1rem)' })},[_c('v-col',{staticClass:"d-flex justify-center align-center pb-3",staticStyle:{"width":"100%"},attrs:{"cols":"auto"}},[(
                                        pop.media.url.split('.').pop() === 'mp4' ||
                                        pop.media.url.split('.').pop() === 'webm' ||
                                        pop.media.url.split('.').pop() === 'mov'
                                    )?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%","object-fit":"cover","cursor":"pointer"}},[_c('video',{key:pop.media.url,staticStyle:{"max-height":"400px","max-width":"330px","display":"block"},attrs:{"controls":""}},[_c('source',{attrs:{"src":pop.media.url,"controls":""}})])]):_c('div',{staticStyle:{"width":"100%"}},[_c('v-img',{style:({ position: 'relative', maxHeight: '200px', cursor: 'pointer' }),attrs:{"contain":"","lazy-src":pop.media.urlThumb,"src":pop.media.urlThumb},on:{"click":function($event){return _vm.openImage(pop.media.url)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('div',{style:((_vm.$vuetify.theme.dark)
                                                ? {
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px'
                                                }
                                                : {
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px'
                                                })},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem({media: pop.media})}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)])],1)]),_c('v-expansion-panels',{attrs:{"flat":"","value":1}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.35","margin-bottom":"0.6%"}},[_vm._v("Toggle Tag View")])]),_c('v-expansion-panel-content',[_c('v-col',{staticClass:"d-flex justify-center my-n5 pb-6 flex-wrap",staticStyle:{"gap":"1em"},attrs:{"cols":"auto"}},_vm._l((pop.media.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"text-capitalize mt-3 flex-grow-0 flex-shrink-0",style:('height: 25px; background: none; border: 1px solid '+_vm.addColorToTag(tag)),on:{"click":function($event){return _vm.searchTag(tag)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(tag.value.toUpperCase()))])]}}],null,true)},[_c('span',[_vm._v(_vm._s((tag.categoryName)))])])],1)}),1)],1)],1)],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }