<template>
    <div>
    <v-row class="px-8 py-8 ma-0">
      <!--<v-col
        cols="10"
        class="pa-2 ma-0"
        style="height: 20px"
      >-->
      
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="white darken-3"
            class="mr-n1"
            style="width: 1%"
            icon
            @click="contactFormDialog = true"
          >
            Contact Us
          </v-btn>
        </template>
        <span>Contact </span>
      </v-tooltip>

      <v-dialog
        v-show="contactFormDialog"
        v-model="contactFormDialog"
        persistent
        width="700"
      >
  
        <form>
          <div class="row">
            <div class="col-md-12">
              <v-card>
                <v-card-title
                  class="white--text primary">
                  Contact Us
                </v-card-title>
                <form @submit.prevent>
                  <div>
                    <v-row class="ma-0">
                      <v-col cols="6">
                        <v-text-field
                          v-model="contactFormDialog.fname"
                          outlined
                          maxlength="50"
                          label="First Name *" 
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="contactFormDialog.lname"
                          outlined
                          maxlength="50"
                          label="Last Name *"
                        />
                      </v-col>
                    </v-row>

                    <v-col cols="12">
                      <v-text-field
                        v-model="contactFormDialog.email"
                        outlined
                        maxlength="50"
                        label="Email *"
                      />
                    </v-col>   
                    <v-col cols="12">
                      <v-textarea style="height:100px,"
                        v-model="contactFormDialog.message"
                        maxlength="1000"
                        label="Description"
                        rows="1"
                        auto-grow
                        outlined
                        hide-details
                        class="mt-n7 textarea-padded"
                      />
                    </v-col>

                    <!-- Upload media -->

                    <div style="width: 150px;">
                      <v-col>
                        <input
                          type="file"
                          multiple
                          hidden
                          @change=""
                        />
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              ref="dragDiv"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                background-color: transparent;
                                border-radius: 5%;
                                border: grey 1px dashed;
                                margin-bottom: 10px;
                                padding: 10px;
                              "
                            >
                              <div style="width:100%; text-align:center;">
                                <v-icon large>
                                  mdi-cloud-upload
                                </v-icon>
                              </div>
                            </div>
                          </template>
                          <span>Upload Images</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                    <!-- Upload area -->
                  </div>
                </form>
              </v-card>
            </div>
          </div>
        <v-spacer />
        <!-- Contact buttons -->
        <v-row
          justify="center"
          class="my-3"
        >
          <!-- Submit  button -->
          <v-btn
            class="mx-6"
            color="primary"
            @click=""
          >
            Submit
          </v-btn>
          <!-- Decline  button -->
          <v-btn
            class="mx-6"
            color="red"
            @click="contactFormDialog=false"
          >
            Cancel
            </v-btn>
        </v-row>
      </form>
        
    </v-dialog>
    
<!--</v-col>-->
</v-row>
</div>
</template>

<script>
export default {
    name: 'ContactForm',
    data: () => ({
        contactFormDialog: false,
    }),


    method: {
        showContactForm(){
            this.contactFormDialog = true
        },
        uploadFile(e) {
            this.uploadedFile = e.target.files
      }
    }

  
}
</script>