<template>
    <div>
        <v-btn
            :disabled="mapView === 'tag'"
            id="generate-link"
            dark
            @click="showGenerateModal = true"
        >
            <h4>Generate public link</h4>
        </v-btn>
        <v-dialog
            v-model="showGenerateModal"
            persistent
            width="700"
        >
            <v-card id="generateLink">
                <v-card-title class="white--text primary">
                    Link generated successfully!
                </v-card-title>
                <v-card-text class="d-flex flex-column" style="gap: 0.7em">
                    <v-row justify="center" class="mt-6 mx-2 px-6 py-4" style="background-color: #2C2C2C;">
                        <h6
                            class="font-italic text-center" 
                            @click="copyToClipboard" 
                            style="cursor: pointer; width: 95%;"
                        >
                            {{ GenerateLink }}
                        </h6>
                    </v-row>
                    <v-row justify="center" class="my-0 font-thin">
                        <v-checkbox
                            v-model="mapViews"
                            label="Set map view"
                            hide-details
                            ></v-checkbox>
                    </v-row>
                    <v-row justify="center" class="my-0 font-thin">
                        <h5>Click on the link to copy to clipboard</h5>
                    </v-row>
                    
                    <v-row
                        justify="center"
                        class="my-3"
                    >
                        <!-- Close button -->
                        <v-btn
                            class="mx-6"
                            color="red"
                            @click="showGenerateModal=false"
                        >
                            Close
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    name: 'GenerateLink',
    props: ['pageSize', 'finalSearchValue', 'sortBy', 'orderBy', 'mapView'],
    data: () => ({
        showGenerateModal: false,
        mapViews: false,
        origin: '',
    }),
    mounted() {
    },
    watch: {
        mapView:{
            handler () {
                if(this.mapView === 'map'){
                    this.mapViews = true
                } else{
                    this.mapViews = false
                }
            }
        }
    },
    computed: {
        GenerateLink(){
            if(window.location.origin.includes('deliver.dooh.com')){
                this.origin = 'https://pop.dooh.com'
            } else if(window.location.origin.includes('stage.deliverdooh.com')){
                this.origin = 'https://popstage.deliverdooh.com'
            } else {
                this.origin = window.location.origin
            }
            let str = this.origin+'/#/pop-gallery?pageSize='+this.pageSize+'&startingAt=0&searchValues='+encodeURIComponent(this.finalSearchValue)+'&sortCategoryId='+this.sortBy.id+'&orderBy='+this.orderBy.value+'&mapView='+this.mapViews
            return str
        },
    },
    methods: {
        copyToClipboard(){
            navigator.clipboard.writeText(this.GenerateLink)
            this.$root.$emit(
                'snackbarSuccess',
                'Generated link copied to clipboard.',
            )
        }
    }
}
</script>

<style scoped>
    #generate-link {
        opacity: 0.85;
    }
    #generate-link:hover {
        opacity: 1;
    }

</style>