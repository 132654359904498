<template>
    <div v-if="$store.state.PopGal.isClipboardEmpty">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    class="mr-3 d-flex justify-center align-center"
                    style="width: 60px; height: 60px; border-radius: 50%;"
                >
                    <v-btn
                        class="d-flex justify-center align-center"
                        fab
                        color="gray"
                        @click="showEditDialog"
                      >
                        <v-icon color="white">mdi-dots-vertical</v-icon>
                      </v-btn>
                </div>
            </template>
            <span>Edit clipboard</span>
        </v-tooltip>

        <!-- Edit Dialog -->
        <v-dialog
            v-show="editPopDialog"
            v-model="editPopDialog"
            persistent
            width="800"
        >
            <v-card class="overflow-x-hidden">
                <v-card-title class="white--text primary">
                    Edit Clipboard
                </v-card-title>
                <v-card-text>
                    <!-- Select Campaign/Burst/Screen -->
                    <v-row class="my-n2 px-4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <v-switch
                                    v-model="campaignSwitch"
                                    :label="`Apply campaign data to every pop selected?`"
                                ></v-switch>
                            </div>
                        </v-col>
                        <v-expand-transition mode="in-out">
                            <v-container v-if="campaignSwitch" class="d-flex row">
                                <v-col cols="12" class="px-2 mb-n8">
                                    <v-select
                                        v-model="selectedCampaign"
                                        :items="campaigns"
                                        :item-text="'name'"
                                        label="Campaign"
                                        outlined
                                        return-object
                                        clearable
                                        @change="getCampaignData()"
                                        @click:clear="
                                            selectedCampaign = null
                                            selectedBurst = null
                                            selectedScreen = null
                                        "
                                    ></v-select>
                                </v-col>
                                <v-col cols="6" class="px-2">
                                    <v-select
                                        :disabled="!selectedCampaign"
                                        v-model="selectedBurst"
                                        :items="burstsByCampaign"
                                        :item-text="'name'"
                                        label="Burst"
                                        outlined
                                        return-object
                                        clearable
                                        @change="changeBurst()"
                                        @click:clear="
                                            selectedBurst = null
                                            selectedScreen = null
                                        "
                                    ></v-select>
                                </v-col>
                                <v-col cols="6" class="px-2">
                                    <v-select
                                        :disabled="!selectedBurst"
                                        v-model="selectedScreen"
                                        :items="SortScreens"
                                        key="id"
                                        value="id"
                                        :item-text="item => `${item.screen.mediaOwner.friendlyName} - ${item.screen.name}`"
                                        label="Screen"
                                        outlined
                                        return-object
                                        clearable
                                        @click:clear="
                                            selectedScreen = null
                                        "
                                    ></v-select>
                                </v-col>
                            </v-container>
                        </v-expand-transition>
                    </v-row>
                    <!-- Global tag add/remove -->
                    <v-row class="mt-n6 px-4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <v-switch
                                    v-model="tagSwitch"
                                    :label="`Manage tags?`"
                                ></v-switch>
                            </div>
                        </v-col>
                        <v-expand-transition mode="in-out">
                            <v-container v-if="tagSwitch" class="d-flex row">
                                <v-col cols="12" class="px-2">
                                            <div class="d-flex justify-space-between">
                                                <h4>General Tag(s)</h4>
                                                <div class="d-flex align-center">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :disabled="addTag === null"
                                                                v-on="on"
                                                                v-bind="attrs"
                                                                @click="
                                                                    $store.dispatch('PopGal/addTag', {
                                                                        proofOfPlayId: 0,
                                                                        categoryId: selectedUploadCategory.id, 
                                                                        shortName: selectedUploadCategory.shortName,
                                                                        category: selectedUploadCategory.name, 
                                                                        value: addTag
                                                                    })
                                                                    addTagtoPops()
                                                                    // function to add tags to list for eachpop in clipboard
                                                                    addTag = null
                                                                "
                                                                fab color="primary"
                                                                style="width: 26px; height: 26px; margin-top: 0.25em;"
                                                            >
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Add tag</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" class="px-2">
                                            <v-select
                                                v-model="selectedUploadCategory"
                                                :items="Categories"
                                                item-value="id"
                                                item-text="name"
                                                label="Tag Category"
                                                outlined
                                                return-object
                                                @change="
                                                    addTag = null
                                                "
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="6" class="px-2">
                                            <v-combobox
                                                :disabled="selectedUploadCategory === null"
                                                :items="Values"
                                                :item-text="'value'"
                                                v-model="addTag"
                                                label="Add Tag"
                                                clearable
                                                outlined
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="12" class="mt-n4 mb-2 px-2">
                                            <div class="mt-n3 text-center">
                                                <v-chip
                                                    v-for="(tag, index) in AllTags" :key="index"
                                                    class="mx-2 mt-4 text-uppercase"
                                                    close
                                                    @click:close="
                                                        // Function to remove thats if a match in value of the tag has been found in pop
                                                        removeTagsfromPops(tag)
                                                        $store.dispatch(`PopGal/removeTag`, {tag})
                                                    "
                                                    :style="'background: none; border: 1px solid '+ addColorToTag(tag)"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <span
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                    {{tag.value.toUpperCase()}}
                                                                </span>
                                                        </template>
                                                        <span>{{(tag.categoryName)}}</span>
                                                    </v-tooltip>
                                                </v-chip>
                                            </div>
                                        </v-col>
                            </v-container>
                        </v-expand-transition>
                    </v-row>
                    <v-divider class="my-8" style="opacity: 15%"></v-divider>
                    <!-- List of pop to edit-->
                    <v-expansion-panels>
                        <v-expansion-panel  
                            v-for="(pop) in $store.state.PopGal.listPops"
                            :key="pop.id"
                            style="width: 100%;"
                        >
                            <v-expansion-panel-header v-slot="{ open }">
                                <v-row no-gutters>
                                    <v-col cols="2" style="width: 100%;">
                                        <div
                                            v-if="
                                                pop.media.url.split('.').pop() === 'mp4' ||
                                                pop.media.url.split('.').pop() === 'webm' ||
                                                pop.media.url.split('.').pop() === 'mov'
                                            "
                                            class="d-flex justify-start align-center ml-1"
                                            style="width: 100%; object-fit: cover; cursor: pointer"
                                        >  
                                            <video
                                                :key="pop.media.url"
                                                style="
                                                    max-height: 10em;
                                                    max-width: 6em;
                                                    display: block;
                                                "
                                                controls
                                                >
                                                <source
                                                    :src="pop.media.url"
                                                    controls
                                                >
                                            </video>
                                        </div>
                                        <div v-else>
                                            <v-img
                                                contain
                                                :lazy-src="pop.media.urlThumb"
                                                :src="pop.media.urlThumb"
                                                :style="{ position: 'relative', maxHeight: '10em', maxWidth: '6em', cursor: 'pointer' }"
                                                @click="openImage(pop.media.url)"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="10"
                                        class="text--secondary d-flex justify-space-around align-center pr-1"
                                    >
                                        <!--<span v-if="open">Editing FP #{{ pop.id }}</span>-->
                                        <v-row
                                            no-gutters
                                            class="text-center pl-8"
                                            v-if="popsData.find(item => item.popId === pop.id)"
                                        >
                                            <v-col cols="4">
                                            Campaign Name:
                                            </v-col>
                                            <v-col cols="4">
                                            Burst:
                                            </v-col>
                                            <v-col cols="4">
                                            Screen:
                                            </v-col>
                                            <v-row class="mt-1">
                                                <v-col cols="4">
                                                {{ popsData.find(item => item.popId === pop.id).campaign }}
                                                </v-col>
                                                <v-col cols="4">
                                                {{ popsData.find(item => item.popId === pop.id).burst }}
                                                </v-col>
                                                <v-col cols="4" class="ml-n2">
                                                {{ popsData.find(item => item.popId === pop.id).screen }}
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row
                                justify="space-around"
                                no-gutters
                                >
                                    <!-- Tags -->
                                    <v-row class="mt-7 px-4">
                                        <v-col cols="12" class="px-2">
                                            <div class="d-flex justify-space-between">
                                                <h4>FP# {{ pop.id }}</h4>
                                                <div class="d-flex align-center">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                :disabled="addTag === null"
                                                                v-on="on"
                                                                v-bind="attrs"
                                                                @click="
                                                                    pop.media.tags.push({
                                                                        proofOfPlayId: pop.id,
                                                                        categoryId: selectedUploadCategory.id, 
                                                                        shortName: selectedUploadCategory.shortName,
                                                                        category: selectedUploadCategory.name, 
                                                                        value: addTag
                                                                    })
                                                                    tagsToAdd.push({
                                                                        proofOfPlayId: pop.id,
                                                                        categoryId: selectedUploadCategory.id, 
                                                                        shortName: selectedUploadCategory.shortName,
                                                                        category: selectedUploadCategory.name, 
                                                                        value: addTag
                                                                    })
                                                                    addTag = null
                                                                "
                                                                fab color="primary"
                                                                style="width: 26px; height: 26px; margin-top: 0.25em;"
                                                            >
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Add tag</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" class="px-2">
                                            <v-select
                                                v-model="selectedUploadCategory"
                                                :items="Categories"
                                                item-value="id"
                                                item-text="name"
                                                label="Tag Category"
                                                outlined
                                                return-object
                                                @change="
                                                    addTag = null
                                                "
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="6" class="px-2">
                                            <v-combobox
                                                :disabled="selectedUploadCategory === null"
                                                :items="Values"
                                                :item-text="'value'"
                                                v-model="addTag"
                                                label="Add Tag"
                                                clearable
                                                outlined
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="12" class="mt-n4 mb-2 px-2">
                                            <div class="mt-n3 text-center">
                                                <v-chip
                                                    v-for="(tag, index) in pop.media.tags" :key="index"
                                                    class="mx-2 mt-4 text-uppercase"
                                                    close
                                                    @click:close="
                                                        tagsToRemove.push(tag)
                                                        pop.media.tags.splice(index, 1)
                                                    "
                                                    :style="'background: none; border: 1px solid '+ addColorToTag(tag)"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <span
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                    {{tag.value.toUpperCase()}}
                                                                </span>
                                                        </template>
                                                        <span>{{(tag.categoryName)}}</span>
                                                    </v-tooltip>
                                                </v-chip>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- Upload buttons -->
                    <v-row
                        justify="center"
                        class="my-3 mt-7"
                    >
                        <!-- Confirm upload button -->
                        <v-btn
                            class="mx-6"
                            color="primary"
                            @click="confirmEditClipboard()"
                        >
                            Confirm
                        </v-btn>
                        <!-- Decline upload button -->
                        <v-btn
                            class="mx-6"
                            color="red"
                            @click="
                                popsData = []
                                editPopDialog=false
                            "
                        >
                            Cancel
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
            
        </v-dialog>
    </div>
</template>

<script>
import CampaignController from "@/services/controllers/Campaign"

export default {
    // campaigns = list of all campaigns
    // tagCategories = list of all tag categories
    // dotList = list of all dots
    props: ['campaigns', 'tagCategories', 'dotList'],
    data() {
        return {
            // Pop modal control variable
            editPopDialog: false,
            // Selected Pops data
            popsData: [],
            // Tags
            selectedUploadCategory: null,
            tagsToRemove: [],
            tagsToAdd: [],
            addTag: null,
            // Campaigns
            selectedCampaign: null,
            // Screen
            screensByBurst: [],
            selectedScreen: null,
            // Bursts
            burstsByCampaign: [],
            selectedBurst: null,
            // Toggles
            campaignSwitch: false,
            tagSwitch: false,
        }
    },

    computed: {
        Categories() {
            return this.tagCategories.map((data) => {
                return data
            })
        },
        Values() {
            let values = []
            if(this.selectedUploadCategory) {
                this.tagCategories.forEach((item) => {
                    if(item.shortName === this.selectedUploadCategory.shortName)
                    item.tags.forEach((tag)=>{
                        values.push(tag.value)
                    })
                })
            }
            return values
        },
        AllTags() {
            let tags = []
            this.$store.state.PopGal.listPops.map((pop) => {
                pop.media.tags.map((tag) =>{
                    tags.push(tag)
                })
            })
            let tagArr = this.removeDuplicatesBy( x => x.value, tags)
            return tagArr
        },
        SortScreens(){
            let arr = [...this.screensByBurst]
            return this.quickSortFormats(arr)
        }
    },
    inject: ['dynamicSort', 'addColorToTag', 'updatePops', 'openImage'],
    methods: {
        // Sort Screens
        quickSortFormats(arr) {
            if(arr.length !== 0) {
                let clone = [...arr]
                clone.sort((a, b) => 
                    a.screen.mediaOwner.friendlyName.localeCompare(b.screen.mediaOwner.friendlyName) ||
                    a.screen.name.localeCompare(b.screen.name)
                )
                return clone
            }
        },
        /** Confirm actions */
        // Campaign/Burst/Screen update
        async confirmEditCampaigns(popList) {
            // Get a pop from array
            const nextPop = popList.shift()
            // If a pop exists

            if(nextPop && (nextPop && this.campaignSwitch)){
                // Data object to send to api
                let updatedPop = {
                    proofOfPlayId: nextPop.id,
                    campaignId: this.selectedCampaign !== null ? this.selectedCampaign.id : 0,
                    campaignBurstId: this.selectedBurst !== null ? this.selectedBurst.id : 0,
                    campaignBurstScreenId: this.selectedScreen !== null ? this.selectedScreen.id : 0
                }
                try{
                    // Api call
                    const updatePop = await CampaignController.updatePoP(updatedPop)
                    if(!updatePop){return}
                    // Filter dot for display (remove from media tags, append to pop object so it does not display in tags list)
                    const dot = updatePop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    updatePop.data['dot'] = dot[0]
                    updatePop.data.media.tags = updatePop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    // Call parent function to update pop on the front
                    await this.updatePops(updatePop)
                    // Display success bar
                    this.$root.$emit(
                            'snackbarSuccess',
                            'Successfully edited PoP.',
                    )
                    // Call recursion
                    this.confirmEditCampaigns(popList)
                } catch(err){
                    // Call recursion
                    this.confirmEditCampaigns(popList)
                    // Console log error
                    console.log(err)
                }
            } else {
                if(this.tagSwitch || this.campaignSwitch) {
                    // Close dialog + clear data 
                    // Check if any existing tag has been removed
                    if(this.tagsToRemove.length !== 0){
                        await this.deleteFilteredTags(this.tagsToRemove)
                    }
                    // Check if any new tags have been added
                    if(this.tagsToAdd.length !== 0){
                        await this.uploadTagtoPop(this.tagsToAdd)
                    }
                    this.clearEditData()
                } else{
                    this.$root.$emit(
                            'snackbarSuccess',
                            'Switch on one of the toggles to perform further actions.',
                    )
                }
                
            }
        },
        // Remove tag list append for eachpop
        removeTagsfromPops(tag){
            this.$store.state.PopGal.listPops.map((pop) => {
                const tagFound = pop.media.tags.find(e => e.value.toUpperCase() === tag.value.toUpperCase())
                if(tagFound){
                    this.tagsToRemove.push(tagFound)
                }
            })
        },
        // Add tag to each pop 
        addTagtoPops() {
            this.$store.state.PopGal.listPops.map((pop) => {
                const tag = {
                                proofOfPlayId: pop.id,
                                categoryId: this.selectedUploadCategory.id, 
                                shortName: this.selectedUploadCategory.shortName,
                                category: this.selectedUploadCategory.name, 
                                value: this.addTag
                            }
                this.tagsToAdd.push(tag)
                //const tagFound = pop.media.tags.find(e => e.value.toUpperCase() === tag.value.toUpperCase())
            })
        },
        // Upload tags
        async uploadTagtoPop(tags){
            const selectedTag = tags.shift()
            if(selectedTag){
                try{
                    const popTag = {
                        proofOfPlayId: selectedTag.proofOfPlayId,
                        categoryId: selectedTag.id,
                        categoryShortName: selectedTag.shortName,
                        value: selectedTag.value
                    }
                    const addTagToPop = await CampaignController.addPoPTag(popTag)
                    if(!addTagToPop){return}
                    const dot = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    addTagToPop.data['dot'] = dot[0]
                    addTagToPop.data.media.tags = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(addTagToPop)
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully added tags to PoP.',
                    )
                    await this.uploadTagtoPop(tags)
                } catch(err){
                    await this.uploadTagtoPop(tags)
                    this.$root.$emit('snackbarError',''+ err.response.data.message )
                }
            }
        },
        // Delete tags
        async deleteFilteredTags(tags){
            const nextTag = tags.shift()
            if(nextTag){
                try{
                    const deletePop = await CampaignController.deletePoPTag(nextTag.mediaTagId)
                    if(!deletePop){return}
                    const dot = deletePop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    deletePop.data['dot'] = dot[0]
                    deletePop.data.media.tags = deletePop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(deletePop)
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully delete tag from PoP.',
                    )
                    this.deleteFilteredTags(tags)
                } catch(err){
                    this.deleteFilteredTags(tags)
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                }
            }
        },
        // Main controller 
        async confirmEditClipboard() {
            // Make a copy of an array to pass in function for recursion
            const popList = [ ...this.$store.state.PopGal.listPops ]
            this.confirmEditCampaigns(popList)
        },
        /** Open/Clear dialog */
        async clearEditData(){
            // Tags
            this.selectedUploadCategory = null
            this.tagsToRemove = []
            this.tagsToAdd = []
            this.addTag = null
            // Campaign
            this.selectedCampaign = null
            // Screen
            this.screensByBurst = []
            this.selectedScreen = null
            // Bursts
            this.burstsByCampaign = []
            this.selectedBurst = null
            // Toggles
            this.campaignSwitch = false
            // Clear selected pop array
            this.$store.dispatch('PopGal/emptyClipboard')
            this.popsData = []
            // Close dialog
            this.editPopDialog = false
        },
        async showEditDialog(){
            this.$nextTick()
            await this.currentCampaign()
            this.editPopDialog = true
        },
        /** Misc */
        async downloadItem({ media }) {
            let downloadUrl
            let downloadFileName
            let mimeType
            downloadUrl = media.url
            downloadFileName = media.originalFileName
            mimeType = media.mimeType
            let cachebuster = Math.round(new Date().getTime() / 1000);
            const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
            const blob = new Blob([response.data], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = downloadFileName;
            link.click();
            URL.revokeObjectURL(link.href);
        },
        // Remove dublicates
        removeDuplicatesBy(keyFn, array) {
            const mySet = new Set();
            return array.filter(function(x) {
                const key = keyFn(x), isNew = !mySet.has(key);
                if (isNew) mySet.add(key);
                return isNew;
            });
        },
        // Get burst by campaign
        async getCampaignData(){
            if(this.selectedCampaign != null){
                try {
                    // Clear data
                    this.screensByBurst = []
                    this.burstsByCampaign = []
                    this.selectedBurst = null
                    this.selectedScreen = null
                    // Get campaign data api call
                    const getCampaign = await CampaignController.getCampaign(this.selectedCampaign.id)
                    if(!getCampaign){return}
                    // Iterate through campaign found and push them to an array
                    getCampaign.data.campaignBursts.forEach((item) =>{
                        this.burstsByCampaign.push(item)
                    })
                    // Sort by name
                    this.burstsByCampaign.sort(this.dynamicSort("name"))
                } catch(err){
                    console.log(err)
                }
            }
        },
        // Get screens by burst
        changeBurst(){
            // Clear selected
            this.screensByBurst = null
            // Assign screens by burst for display
            if(this.selectedBurst !== undefined)
                this.screensByBurst = this.selectedBurst.campaignBurstScreens
        },
        // Get current campagin title of a pop
        async currentCampaign(){
            this.$store.state.PopGal.listPops.map(async (pop) => {
                const currCampaign = pop.campaignId === 0 ? null : this.campaigns.find(obj => {return obj.id === pop.campaignId})
                if(currCampaign) {
                    await CampaignController.getCampaign(currCampaign.id).then((res)=>{
                        res.data.campaignBursts.forEach((item) =>{
                            this.burstsByCampaign.push(item)
                        })
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        const currBurst = pop.campaignBurstId === 0 ? null : this.burstsByCampaign.find(obj => {return obj.id === pop.campaignBurstId})
                        if(currBurst) {
                            this.screensByBurst = currBurst.campaignBurstScreens
                            var currScreen = pop.campaignBurstScreenId === 0 ? null : this.screensByBurst.find(obj => {return obj.id === pop.campaignBurstScreenId})
                        }
                        const value = {
                            popId: pop.id,
                            campaign: currCampaign ? currCampaign.name : 'Not set', 
                            burst: currBurst ? currBurst.name : 'Not set', 
                            screen: currScreen ? currScreen.screen.name : 'Not set'
                        }
                        this.popsData.push(value)
                    })
                } else{
                    const value = {
                            popId: pop.id,
                            campaign: 'Not set', 
                            burst: 'Not set', 
                            screen: 'Not set'
                        }
                    this.popsData.push(value)
                }
            })
        },
    },

}
</script>

<style scoped>
    .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23px;
        width: 23px;
        border-radius: 50%;
    }

    .dot_checkbox {
        font-size: 16px;
        color: #4E5D53;
    }
</style>