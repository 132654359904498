var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded && this.$store.state.Permissions.proofOfPlayAdd)?_c('div',[_c('div',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.$refs.popUpload.click()},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('input',{ref:"popUpload",attrs:{"type":"file","multiple":"","hidden":""},on:{"change":_vm.uploadFile}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',_vm._g(_vm._b({ref:"dragDiv",staticClass:"rounded",staticStyle:{"background-color":"transparent","padding":"0.66em 0em","transition":"0.1s"},style:(hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}),on:{"drop":_vm.dragFile,"dragleave":function($event){_vm.$refs.dragDiv.style.backgroundColor = 'transparent'},"dragover":function($event){_vm.$refs.dragDiv.style.backgroundColor = 'grey'}}},'div',attrs,false),on),[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-cloud-upload ")])],1)])]}}],null,true)})]}}],null,false,718698193)},[_c('span',[_vm._v("Upload Proof of Play")])])],1),_c('v-dialog',{attrs:{"persistent":"","hide-overlay":"","width":"700"},model:{value:(_vm.showUploadPopsDialog),callback:function ($$v) {_vm.showUploadPopsDialog=$$v},expression:"showUploadPopsDialog"}},[_c('v-card',{attrs:{"id":"showUploadPopsDialog"}},[_c('v-card-title',{staticClass:"white--text primary"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"mb-2",attrs:{"indeterminate":"","color":"white"}}):_vm._e(),_vm._v(" Upload Proof of Play ")],1),_c('v-card-text',{staticClass:"pa-14"},[_c('v-row',[_c('h4',{staticClass:"px-2"},[_vm._v("Files Selected:")])]),_c('v-row',{staticClass:"px-2"},[_c('ul',_vm._l((_vm.uploadedPops),function(media,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(media.name)+" "),_c('v-tooltip',{attrs:{"content-class":"top","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"fab-icon ml-1 mt-n1",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.removeUploadMedia(media)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)}),0)]),_c('v-row',{staticClass:"mt-10"}),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Select Campaign")])])]),_c('v-col',{staticClass:"px-2 mb-n8",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.Campaigns,"item-text":'name',"label":"Campaign","outlined":"","return-object":"","clearable":""},on:{"change":function($event){return _vm.getCampaignData()},"click:clear":function($event){_vm.selectedCampaign = null
                                _vm.selectedBurst = null
                                _vm.selectedScreen = null}},model:{value:(_vm.selectedCampaign),callback:function ($$v) {_vm.selectedCampaign=$$v},expression:"selectedCampaign"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"disabled":!_vm.selectedCampaign,"items":_vm.burstsByCampaign,"item-text":'name',"label":"Burst","outlined":"","return-object":"","clearable":""},on:{"change":function($event){return _vm.changeBurst()},"click:clear":function($event){_vm.selectedBurst = null
                                _vm.selectedScreen = null}},model:{value:(_vm.selectedBurst),callback:function ($$v) {_vm.selectedBurst=$$v},expression:"selectedBurst"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{key:"id",attrs:{"disabled":!_vm.selectedBurst,"items":_vm.screensByBurst,"value":"id","item-text":function (item) { return ((item.screen.mediaOwner.friendlyName) + " - " + (item.screen.name)); },"label":"Screen","outlined":"","return-object":"","clearable":""},on:{"click:clear":function($event){_vm.selectedScreen = null}},model:{value:(_vm.selectedScreen),callback:function ($$v) {_vm.selectedScreen=$$v},expression:"selectedScreen"}})],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Choose date & time")])])]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.selectedDate,"outlined":"","append-icon":"mdi-calendar","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.selectedDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,3660708242)},[_c('v-date-picker',{attrs:{"tabindex":"0","no-title":"","scrollable":""},on:{"input":function($event){return _vm.updateSelectedTags({
                                    categoryId: 4, 
                                    shortName: 'datetimetaken',
                                    category: 'Date/Time Taken', 
                                    value: _vm.selectedDate
                                    })}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between mt-6"},[_c('h4',[_vm._v("General Tag(s)")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-1",staticStyle:{"width":"26px","height":"26px"},attrs:{"disabled":!_vm.addTag,"fab":"","color":"primary"},on:{"click":function($event){_vm.updateSelectedTags({
                                            categoryId: _vm.selectedUploadCategory.id, 
                                            shortName: _vm.selectedUploadCategory.shortName,
                                            category: _vm.selectedUploadCategory.name, 
                                            value: _vm.addTag
                                            })
                                            _vm.addTag = null}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2818320435)},[_c('span',[_vm._v("Add tag")])])],1)]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.Categories,"item-text":'name',"label":"Tag Category","outlined":"","return-object":""},on:{"change":function($event){_vm.addTag = null}},model:{value:(_vm.selectedUploadCategory),callback:function ($$v) {_vm.selectedUploadCategory=$$v},expression:"selectedUploadCategory"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"disabled":!_vm.selectedUploadCategory,"items":_vm.Values,"item-text":'value',"label":"Add Tag","clearable":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.updateSelectedTags({
                                    categoryId: _vm.selectedUploadCategory.id, 
                                    shortName: _vm.selectedUploadCategory.shortName,
                                    category: _vm.selectedUploadCategory.name, 
                                    value: _vm.addTag
                                })
                                _vm.addTag = null}},model:{value:(_vm.addTag),callback:function ($$v) {_vm.addTag=$$v},expression:"addTag"}})],1),_c('v-col',{staticClass:"mt-n4 px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-n3 text-left"},_vm._l((_vm.selectedTags),function(filter,index){return _c('v-chip',{key:index,staticClass:"mx-2 text-uppercase",style:('background: none; border: 1px solid '+ _vm.addColorToTag(filter)),attrs:{"close":""},on:{"click:close":function($event){_vm.selectedUploadValue = null
                                    _vm.selectedTags.splice(index, 1)}}},[_vm._v(_vm._s(filter.value))])}),1)])],1),(_vm.dotsPresent)?_c('v-row',{class:(_vm.selectedTags.length > 0) ? 'mt-2' : 'mt-n4'},[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"label":"Diagnostic Overlay Tag?","color":"red","hide-details":""},model:{value:(_vm.uploadPopCollection.hasDot),callback:function ($$v) {_vm.$set(_vm.uploadPopCollection, "hasDot", $$v)},expression:"uploadPopCollection.hasDot"}})],1)]),(_vm.uploadPopCollection.hasDot)?_c('v-col',{staticClass:"px-2 ml-1 d-flex",attrs:{"cols":"6"}},_vm._l((_vm.dots),function(dot,index){return _c('div',{key:index,staticClass:"dot mr-2",style:({background: dot.color}),on:{"click":function($event){_vm.selectedActiveDot = dot}}},[(_vm.selectedActiveDot && _vm.selectedActiveDot.color === dot.color)?_c('span',{staticClass:"dot_checkbox gray--text",attrs:{"outlined":""}},[_vm._v("✔")]):_vm._e()])}),0):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmUploadToPoPs()}}},[_vm._v(" Confirm ")]),_c('v-btn',{staticClass:"mx-6",attrs:{"color":"red"},on:{"click":function($event){return _vm.clearUploadMedia()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }