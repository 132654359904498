<template>
    <div 
        v-if="dataLoaded && this.$store.state.Permissions.proofOfPlayAdd"
    >
        <div
            @click="$refs.popUpload.click()"
            @dragover.prevent
            @drop.prevent
            style="width: 100%;"
        >
            <input
                ref="popUpload"
                type="file"
                multiple
                hidden
                @change="uploadFile"
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                        <div
                            ref="dragDiv"
                            v-bind="attrs"
                            v-on="on"
                            @drop="dragFile"
                            @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                            @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                            style="
                                background-color: transparent;
                                padding: 0.66em 0em;
                                transition: 0.1s;
                            "
                            :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                            class="rounded"
                            
                        >
                            <div style="width:100%; text-align:center;">
                                <v-icon large>
                                    mdi-cloud-upload
                                </v-icon>
                            </div>
                        </div>
                    </v-hover>
                </template>
                <span>Upload Proof of Play</span>
            </v-tooltip>
        </div>

        <!-- Upload modal -->
        <v-dialog
            v-model="showUploadPopsDialog"
            persistent
            hide-overlay
            width="700"
            class=""
        >
            <v-card 
                id="showUploadPopsDialog"
            >
                <v-card-title class="white--text primary">
                    <v-progress-linear
                        v-if="isLoading"
                        indeterminate
                        color="white"
                        class="mb-2"
                    ></v-progress-linear>
                    Upload Proof of Play
                </v-card-title>
                <v-card-text
                    class="pa-14"
                >
                    <v-row>
                    <h4
                        class="px-2"
                    >Files Selected:</h4>
                    </v-row>

                    <!-- Files selected -->
                    <v-row
                        class="px-2"
                    >
                        <ul>
                            <li v-for="(media, index) in uploadedPops" :key="index">
                                {{media.name}}
                                <v-tooltip
                                    content-class="top"
                                    top
                                >
                                    <template v-slot:activator="{ attrs, on }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="removeUploadMedia(media)"
                                            small  
                                            color="white"
                                            class="fab-icon ml-1 mt-n1"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </template>
                                    <span>Remove</span>
                                </v-tooltip>
                            </li>
                        </ul>
                    </v-row>

                    <!-- Attributes -->
                    <v-row class="mt-10">
                        <!-- Date Picker
                        <v-col cols="6" class="px-2">
                            <v-menu
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-combobox
                                        v-model="uploadPopCollection.startDate"
                                        outlined
                                        label="Date Taken"
                                        append-icon="mdi-calendar"
                                        readonly
                                        clearable
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="uploadPopCollection.startDate"
                                    clearable
                                    range
                                    no-title
                                    scrollable
                                />
                            </v-menu>
                        </v-col>
                        -->
                    </v-row>
                    <!-- Select Campaign/Burst/Screen -->
                    <v-row class="mt-n4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4>Select Campaign</h4>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-2 mb-n8">
                            <v-select
                                v-model="selectedCampaign"
                                :items="Campaigns"
                                :item-text="'name'"
                                label="Campaign"
                                outlined
                                return-object
                                @change="getCampaignData()"
                                @click:clear="
                                    selectedCampaign = null
                                    selectedBurst = null
                                    selectedScreen = null
                                "
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                :disabled="!selectedCampaign"
                                v-model="selectedBurst"
                                :items="burstsByCampaign"
                                :item-text="'name'"
                                label="Burst"
                                outlined
                                return-object
                                @change="changeBurst()"
                                @click:clear="
                                    selectedBurst = null
                                    selectedScreen = null
                                "
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                :disabled="!selectedBurst"
                                v-model="selectedScreen"
                                :items="screensByBurst"
                                key="id"
                                value="id"
                                :item-text="item => `${item.screen.mediaOwner.friendlyName} - ${item.screen.name}`" 
                                label="Screen"
                                outlined
                                return-object
                                clearable
                                @click:clear="
                                    selectedScreen = null
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                    <!-- Date picker-->
                    <v-row class="mt-n4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4>Choose date & time</h4>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-2">
                                  <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="selectedDate"
                                        outlined
                                        append-icon="mdi-calendar"
                                        v-on="on"
                                        v-bind="attrs"
                                        hide-details
                                        clearable
                                        @click:clear="selectedDate = null"
                                      >
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="selectedDate"
                                      @input="updateSelectedTags({
                                        categoryId: 4, 
                                        shortName: 'datetimetaken',
                                        category: 'Date/Time Taken', 
                                        value: selectedDate
                                        })"
                                      tabindex="0"
                                      no-title
                                      scrollable
                                    />
                                  </v-menu>
                        </v-col>
                    </v-row>
                    <!-- Tags -->
                    <v-row class="mt-n4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between mt-6">
                                <h4>General Tag(s)</h4>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :disabled="!addTag"
                                            @click="updateSelectedTags({
                                                categoryId: selectedUploadCategory.id, 
                                                shortName: selectedUploadCategory.shortName,
                                                category: selectedUploadCategory.name, 
                                                value: addTag
                                                })
                                                addTag = null
                                            "
                                            fab color="primary" 
                                            class="mt-1"
                                            style="width: 26px; height: 26px;"
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add tag</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                v-model="selectedUploadCategory"
                                :items="Categories"
                                :item-text="'name'"
                                label="Tag Category"
                                outlined
                                return-object
                                @change="
                                    addTag = null
                                "
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-combobox
                                :disabled="!selectedUploadCategory"
                                :items="Values"
                                :item-text="'value'"
                                v-model="addTag"
                                label="Add Tag"
                                clearable
                                outlined
                                @keyup.enter="
                                    updateSelectedTags({
                                        categoryId: selectedUploadCategory.id, 
                                        shortName: selectedUploadCategory.shortName,
                                        category: selectedUploadCategory.name, 
                                        value: addTag
                                    })
                                    addTag = null
                                "
                            ></v-combobox>
                        </v-col>

                        <v-col cols="12" class="mt-n4 px-2">
                            <div class="mt-n3 text-left">
                                <v-chip
                                    v-for="(filter, index) in selectedTags" :key="index"
                                    class="mx-2 text-uppercase"
                                    close
                                    @click:close="
                                        selectedUploadValue = null
                                        selectedTags.splice(index, 1)
                                    "
                                    :style="'background: none; border: 1px solid '+ addColorToTag(filter)"
                                >{{filter.value}}</v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    
                    <!-- Dots -->
                    <v-row 
                        v-if="dotsPresent"
                        :class="(selectedTags.length > 0) ? 'mt-2' : 'mt-n4'"
                    >
                        <v-col cols="12" class="mt-n8">
                            <div class="d-flex">
                                <v-checkbox
                                    v-model="uploadPopCollection.hasDot"
                                    label="Diagnostic Overlay Tag?"
                                    color="red"
                                    hide-details
                                ></v-checkbox>
                            </div>
                        </v-col>
                        <v-col v-if="uploadPopCollection.hasDot" cols="6" class="px-2 ml-1 d-flex">
                            <div
                                v-for="(dot, index) in dots"
                                :key="index"
                                @click="selectedActiveDot = dot"
                                class="dot mr-2"
                                :style="{background: dot.color}"
                            >
                                <span 
                                    v-if="selectedActiveDot && selectedActiveDot.color === dot.color" 
                                    class="dot_checkbox gray--text"
                                    outlined
                                >✔</span>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- Upload buttons -->
                    <v-row
                        justify="center"
                        class="mt-3"
                    >
                        <!-- Confirm upload button -->
                        <v-btn
                            class="mx-6"
                            color="primary"
                            @click="confirmUploadToPoPs()"
                        >
                            Confirm
                        </v-btn>

                        <!-- Decline upload button -->
                        <v-btn
                            class="mx-6"
                            color="red"
                            @click="clearUploadMedia()"
                        >
                            Cancel
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CampaignController from "@/services/controllers/Campaign"

export default {
    inject: ['dynamicSort', 'addColorToTag', 'appendPop', 'totalPopsIncrement', 'updatePops'],
    props: ['campaigns', 'tagCategories', 'dotList'],
    data() {
        return {
            dataLoaded: false,
            tags: this.tagCategories,
            uploadedPops: [],
            showUploadPopsDialog: false,
            uploadPopCollection: {},
            selectedUploadCategory: null,
            selectedUploadValue: null,
            // Dots
            popCateg: null,
            dots: this.dotList,
            dotsPresent: true,
            selectedTags: [],
            selectedActiveDot: null,
            // Campaigns
            allCampaigns: this.campaigns,
            selectedCampaign: null,
            // Screen
            screensByBurst: [],
            selectedScreen: null,
            // Bursts
            burstsByCampaign: [],
            selectedBurst: null,
            addTag: null,
            // Date
            selectedDate: null,
            // Loader
            isLoading: false
        }
    },

    computed: {
        // tags
        Categories() {
            return this.tags.map((data) => {
                return data
            })
        },
        Values() {
            let values = []
            if(this.selectedUploadCategory) {
                this.tags.forEach((item) => {
                    if(item.shortName === this.selectedUploadCategory.shortName)
                    item.tags.forEach((tag)=>{
                        values.push(tag.value)
                    })
                })
            }
            return values
        },
        // General info
        Campaigns() {
            return this.allCampaigns.map((data) =>{
                return data
            })
        },
    },

    async created() {
        this.dataLoaded = true
    },

    methods: {
        changeBurst(){
            // Clear selected
            this.selectedScreen = null
            // Assign screens by burst for display
            if(this.selectedBurst !== undefined)
                this.screensByBurst = this.selectedBurst.campaignBurstScreens
            this.screensByBurst.sort(this.dynamicSort("name"))
        },
        uploadFile(e) {
            Object.values(e.target.files).forEach(file =>{
                this.uploadedPops.push(file)
            })
            this.showUploadPopsDialog = true
        },
        dragFile(e) {
            // Accept the file
            Object.values(e.dataTransfer.files).forEach(file=>{
                this.uploadedPops.push(file)
            })
            // Change background colour
            this.$refs.dragDiv.style.backgroundColor = 'transparent'
            // Shorthand for if a screen is selected
            this.showUploadPopsDialog = true
        },
        removeUploadMedia(selectedItem) {
            // Filter out removed file
            this.uploadedPops = [...this.uploadedPops].filter(item => {
            if (item.name !== selectedItem.name)
                return item
            })

            // If list empty -> close upload modal
            if(this.uploadedPops.length === 0)
                this.clearUploadMedia()
        },

        async clearUploadMedia() {
            this.isTagsUploaded = false
            // Empty array of images
            this.uploadedPops = []
            // Reset Modal values
            this.uploadPopCollection = {}
            this.selectedUploadCategory = null
            this.selectedUploadValue = null
            this.selectedDate = null
            // Reset Campaign data
            this.screensByBurst = []
            this.burstsByCampaign = []
            this.selectedCampaign = null
            this.selectedBurst = null
            this.selectedScreen = null
            // Reset selected dot
            this.selectedActiveDot= null
            // tags
            this.selectedTags = []
            // Close upload modal
            this.showUploadPopsDialog = false
        },
        async uploadTagtoPop(pop, copyTags){
            const selectedTag = copyTags.shift()
            if(selectedTag){
                try {
                    const popTag = {
                        proofOfPlayId: pop.data.id,
                        categoryId: selectedTag.id,
                        categoryShortName: selectedTag.shortName,
                        value: selectedTag.value
                    }
                    const addTagToPop = await CampaignController.addPoPTag(popTag)
                    if(!addTagToPop){return}
                    const dot = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    addTagToPop.data['dot'] = dot[0]
                    addTagToPop.data.media.tags = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(addTagToPop)
                    this.uploadTagtoPop(pop, copyTags)
                } catch(err) {
                    this.$root.$emit(
                        'snackbarError',
                        ''+ err.response.data.message,
                    )
                    this.uploadTagtoPop(pop, copyTags)
                }
            } else {
                this.$root.$emit(
                    'snackbarSuccess',
                    'Successfully added tags to PoP.',
                )
            }
        },
        async confirmUploadToPoPs() {
            this.isLoading = true
            //  Upload image
            const nextPop = this.uploadedPops.shift()
            if(nextPop){
                const formData = new FormData()
                formData.append('file', nextPop)
                formData.append('feature','ProofOfPlay')
                try {
                    const uploadMedia = await CampaignController.uploadMedia(formData)
                    if(!uploadMedia) {return}
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully uploaded media.',
                    )
                    const popData = {
                        mediaId: uploadMedia.data.id,
                        campaignId: this.selectedCampaign !== null ? this.selectedCampaign.id : 0,
                        campaignBurstId: this.selectedBurst !== null ? this.selectedBurst.id : 0,
                        campaignBurstScreenId: this.selectedScreen !== null ? this.selectedScreen.id : 0,
                        proofOfPlayTypeShortname: "",
                    }
                    const uploadPoP = await CampaignController.addPoP(popData)
                    if(!uploadPoP){return}
                    const dot = uploadPoP.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    uploadPoP.data['dot'] = dot[0]
                    uploadPoP.data.media.tags = uploadPoP.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.appendPop(uploadPoP)
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully added Proof of Play.',
                    )
                    if(this.selectedTags.length !== 0) {
                        const copyTags = [...this.selectedTags]
                        await this.uploadTagtoPop(uploadPoP, copyTags)
                    }
                    if(this.selectedActiveDot !== null) {
                        const dotTag = {
                            proofOfPlayId: uploadPoP.data.id,
                            categoryId: 5,
                            categoryShortName: "errordot",
                            value: this.selectedActiveDot.name+" "+this.selectedActiveDot.color
                        }
                        const addTagToPop = await CampaignController.addPoPTag(dotTag)
                        if(!addTagToPop){return}
                        const dot = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                        addTagToPop.data['dot'] = dot[0]
                        addTagToPop.data.media.tags = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                        await this.updatePops(addTagToPop)
                        this.$root.$emit(
                            'snackbarSuccess',
                            'Successfully added dot to PoP.',
                        )
                    }
                    this.totalPopsIncrement()
                    this.confirmUploadToPoPs()
                } catch (err) {
                    this.confirmUploadToPoPs()
                    console.log(err)
                }
            } else {
                await this.clearUploadMedia()
                this.isLoading = false
                await this.$nextTick()
            }
        },
        async getCampaignData(){
            if(this.selectedCampaign != null){
                try {
                    // Clear and populate select el's when dif campaign chosen
                    this.screensByBurst = []
                    this.burstsByCampaign = []
                    this.selectedBurst = null
                    this.selectedScreen = null
                    const getCampaign = await CampaignController.getCampaign(this.selectedCampaign.id)
                    if(!getCampaign){return}
                    getCampaign.data.campaignBursts.forEach((item) =>{
                        this.burstsByCampaign.push(item)
                    })
                    this.burstsByCampaign.sort(this.dynamicSort("name"))
                } catch(err){
                    console.log(err)
                }
            }
        },
        async updateSelectedTags(tag) {
            this.selectedTags.push(tag) 
        } 
    }
}
</script>

<style scoped>
    #uploadBtn:hover{
        border: solid 1px rgba(255,255,255, .8);
    }
    .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23px;
        width: 23px;
        border-radius: 50%;
    }

    .dot_checkbox {
        font-size: 16px;
        color: #4E5D53;
    }
</style>