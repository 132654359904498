<template>
    <div v-show="$store.state.Permissions.proofOfPlayEdit">
        <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="white darken-3"
                        style="width: 0%; margin-right: -0.5em"
                        icon
                        @click="showEditDialog()"
                    >
                        <v-icon style="font-size: 1.9em">mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>Edit PoP</span>
        </v-tooltip>

        <!-- Edit Dialog -->
        <v-dialog
            v-show="editPopDialog"
            v-model="editPopDialog"
            persistent
            width="700"
        >
            <v-card id="editPopDialog" class="overflow-x-hidden">
                <v-card-title class="white--text primary">
                    Edit Proof of Play - FP#{{data.id}}
                </v-card-title>
                <!-- Upload buttons -->
                <v-card-text>
                    <!-- Select Campaign/Burst/Screen -->
                    <v-row class="my-n2 px-4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4 >Select Campaign</h4>
                                <!-- Delete Comments
                                <div class="d-flex align-center justify-end">
                                    <Comments v-bind:data="selectedPop"/>
                                </div>
                                -->
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-2 mb-n8">
                            <v-select
                            
                                v-model="selectedCampaign"
                                :items="Campaigns"
                                :item-text="'name'"
                                label="Campaign"
                                outlined
                                return-object
                                clearable
                                @change="getCampaignData()"
                                @click:clear="
                                    selectedCampaign = null
                                    selectedBurst = null
                                    selectedScreen = null
                                "
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                :disabled="!selectedCampaign"
                                v-model="selectedBurst"
                                :items="burstsByCampaign"
                                :item-text="'name'"
                                label="Burst"
                                outlined
                                return-object
                                clearable
                                @change="changeBurst()"
                                @click:clear="
                                    selectedBurst = null
                                    selectedScreen = null
                                "
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                :disabled="!selectedBurst"
                                v-model="selectedScreen"
                                :items="SortScreens"
                                key="id"
                                value="id"
                                :item-text="item => `${item.screen.mediaOwner.friendlyName} - ${item.screen.name}`"
                                label="Screen"
                                outlined
                                return-object
                                clearable
                                @click:clear="
                                    selectedScreen = null
                                "
                            ></v-select>
                        </v-col>
                    </v-row>
                    <!-- Date picker-->
                    <v-row v-if="selectedPop.media.tags.find(obj => {return obj.shortName === 'datetimetaken'}) === undefined" class="mt-n6 px-4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4>Choose date & time</h4>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-2">
                                  <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="selectedDate"
                                        outlined
                                        :append-icon="selectedDate ? '' : 'mdi-calendar'"
                                        v-on="on"
                                        v-bind="attrs"
                                        hide-details
                                        clearable
                                        @click:clear="selectedDate = null"
                                      >
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="selectedDate"
                                      @input="
                                        addTagToUpload({    
                                            categoryId: 4, 
                                            shortName: 'datetimetaken',
                                            category: 'Date/Time Taken', 
                                            value: selectedDate
                                        })
                                        "
                                      tabindex="0"
                                      no-title
                                      scrollable
                                    />
                                  </v-menu>
                        </v-col>
                    </v-row>
                    <!-- Tags -->
                    <v-row class="mt-7 px-4">
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4>General Tag(s)</h4>
                                <div class="d-flex align-center">
                                    <!-- 
                                    <EditGlobal :tagCategs="tags" />-->
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                :disabled="addTag === null"
                                                v-on="on"
                                                v-bind="attrs"
                                                @click="
                                                    addTagToUpload({  
                                                        categoryId: selectedUploadCategory.id, 
                                                        shortName: selectedUploadCategory.shortName,
                                                        category: selectedUploadCategory.name, 
                                                        value: addTag
                                                    })
                                                    addTag = null
                                                "
                                                fab color="primary"
                                                style="width: 26px; height: 26px; margin-top: 0.25em;"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add tag</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                v-model="selectedUploadCategory"
                                :items="Categories"
                                item-value="id"
                                item-text="name"
                                label="Tag Category"
                                outlined
                                return-object
                                @change="
                                    addTag = null
                                "
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-combobox
                                :disabled="selectedUploadCategory === null"
                                :items="Values"
                                :item-text="'value'"
                                v-model="addTag"
                                label="Add Tag"
                                clearable
                                outlined
                            ></v-combobox>
                        </v-col>

                        <v-col cols="12" class="mt-n4 mb-2 px-2">
                            <div class="mt-n3 text-center">
                                <v-chip
                                    v-for="(tag, index) in data.media.tags" :key="index"
                                    class="mx-2 mt-4 text-uppercase"
                                    close
                                    @click:close="
                                        tagsToRemove.push(tag)
                                        selectedPop.media.tags.splice(index, 1)
                                    "
                                    :style="'background: none; border: 1px solid '+ addColorToTag(tag)"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    {{tag.value.toUpperCase()}}
                                                </span>
                                        </template>
                                        <span>{{(tag.categoryName)}}</span>
                                    </v-tooltip>
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- Dots -->
                    <v-row 
                        v-if="selectedPop.dot != undefined"
                        class="mt-5 px-4"
                    >
                        <v-col cols="12" class="mt-n8">
                            <div class="d-flex">
                                <v-checkbox
                                    :disabled="dotSelected"
                                    label="Remove existing dot?"
                                    color="red"
                                    hide-details
                                    @click="
                                        deleteDot = !deleteDot
                                        dotSelected = false
                                    "
                                ></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row 
                        :class="dotSelected ? 'mt-7 px-4' : 'mt-5 px-4'"
                    >
                        <v-col cols="12" class="mt-n8">
                            <div class="d-flex">
                                <v-checkbox
                                
                                    :disabled="deleteDot"
                                    v-model="dotSelected"
                                    label="Diagnostic Overlay Tag?"
                                    color="red"
                                    hide-details
                                    @click="
                                        deleteDot = false
                                    "
                                ></v-checkbox>
                            </div>
                        </v-col>
                        <v-col v-if="dotSelected" cols="6" class="px-2 ml-1 d-flex">
                            <div
                                v-for="(dot, index) in dots"
                                :key="index"
                                @click="selectedActiveDot = dot"
                                class="dot mr-2"
                                :style="{background: dot.color}"
                            >
                                <span 
                                    v-if="selectedActiveDot && selectedActiveDot.color === dot.color" 
                                    class="dot_checkbox gray--text"
                                    outlined
                                >✔</span>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- Upload buttons -->
                    <v-row
                        justify="center"
                        class="my-3 mt-7"
                    >
                        <!-- Confirm upload button -->
                        <v-btn
                            :disabled='addTag !== null'
                            class="mx-6"
                            color="primary"
                            @click="editPopConfirm()"
                        >
                            Confirm
                        </v-btn>
                        <!-- Decline upload button -->
                        <v-btn
                            class="mx-6"
                            color="red"
                            @click="editPopDialog=false"
                        >
                            Cancel
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
            
        </v-dialog>
    </div>
</template>

<script>
import CampaignController from "@/services/controllers/Campaign"
import EditGlobal from './EditGlobalTags.vue'
import Comments from './timeline/Comments.vue'

export default {
    components: {
        EditGlobal,
        Comments
    },
    props: ['data', 'campaigns', 'tagCategories', 'dotList'],
    data() {
        return {
            selectedPop: this.data,
            editPopDialog: false,
            // Copy pop data for edit
            uploadPopData: null,
            campaignHasChanged: false,
            burstHasChanged: false,
            screenHasChanged: false,
            // Dots
            dotSelected: false,
            deleteDot: false,
            popCateg: null,
            dots: this.dotList,
            selectedActiveDot: null,
            //selectedActiveDot: this.selectedPop.dot === undefined ? null : (this.dots.find(obj => {return obj.color === this.selectedPop.dot.value.split(" ")[1]})),
            // Tags
            tags: this.tagCategories,
            selectedUploadCategory: null,
            tagsToRemove: [],
            tagsToAdd: [],
            addTag: null,
            // Campaigns
            allCampaigns: this.campaigns,
            selectedCampaign: null,
            // Screen
            screensByBurst: [],
            selectedScreen: null,
            // Bursts
            burstsByCampaign: [],
            selectedBurst: null,
            // Date picker
            selectedDate: null,
            // Delete Pop btn
            deleteContentDialog: false,
            selectedContentForEdit: null,
        }
    },

    computed: {
        Categories() {
            return this.tags.map((data) => {
                return data
            })
        },
        Values() {
            let values = []
            if(this.selectedUploadCategory) {
                this.tags.forEach((item) => {
                    if(item.shortName === this.selectedUploadCategory.shortName)
                    item.tags.forEach((tag)=>{
                        values.push(tag.value)
                    })
                })
            }
            return values
        },
        Campaigns() {
            return this.allCampaigns.map((data) =>{
                return data
            })
        },
        SortScreens(){
            let arr = [...this.screensByBurst]
            return this.quickSortFormats(arr)
        }
    },

    inject: ['dynamicSort', 'addColorToTag', 'updatePops'],
    methods: {
        quickSortFormats(arr) {
            if(arr.length !== 0) {
                let clone = [...arr]
                clone.sort((a, b) => 
                    a.screen.mediaOwner.friendlyName.localeCompare(b.screen.mediaOwner.friendlyName) ||
                    a.screen.name.localeCompare(b.screen.name)
                )
                return clone
            }
        },
        // Populate date/time if tag exits
        isDate() {
            if(this.selectedPop.media.tags.some(item => item.shortName === 'datetimetaken')){
                const date = this.selectedPop.media.tags.filter(item => item.shortName === 'datetimetaken')[0]
                if(date !== undefined)
                    this.selectedDate = date.value
            } else{
                this.selectedDate = null
            }
        },
        changeBurst(){
            // Clear selected
            this.screensByBurst = null
            // Assign screens by burst for display
            if(this.selectedBurst !== undefined)
                this.screensByBurst = this.selectedBurst.campaignBurstScreens
        },
        async getInitialBurst(){
            if(this.selectedPop.campaignId !== 0){
                try{
                    const getCampaign = await CampaignController.getCampaign(this.selectedCampaign.id)                    
                    if(!getCampaign){return}
                    getCampaign.data.campaignBursts.forEach((item) =>{
                        this.burstsByCampaign.push(item)
                    })
                    const currBurst = this.selectedPop.campaignBurstId === 0 ? null : this.burstsByCampaign.find(obj => {return obj.id === this.selectedPop.campaignBurstId})
                    this.selectedBurst = currBurst
                    this.screensByBurst = this.selectedBurst.campaignBurstScreens
                    const currScreen = this.selectedPop.campaignBurstScreenId === 0 ? null : this.screensByBurst.find(obj => {return obj.id === this.selectedPop.campaignBurstScreenId})
                    this.selectedScreen = currScreen
                }catch(err){
                    console.log(err)
                }
            }
        },
        async getCampaignData(){
            if(this.selectedCampaign != null){
                try {
                    this.screensByBurst = []
                    this.burstsByCampaign = []
                    this.selectedBurst = null
                    this.selectedScreen = null
                    const getCampaign = await CampaignController.getCampaign(this.selectedCampaign.id)
                    if(!getCampaign){return}
                    getCampaign.data.campaignBursts.forEach((item) =>{
                        this.burstsByCampaign.push(item)
                    })
                    this.burstsByCampaign.sort(this.dynamicSort("name"))
                } catch(err){
                    console.log(err)
                }
            }
        },
        async isEditChanged(){
            // Check Campaign has changed
            if(this.selectedCampaign !== null && (this.selectedPop.campaignId !== this.selectedCampaign.id)){
                this.campaignHasChanged = true
            }
            // Check Burst has changed
            if(this.selectedBurst !== null && (this.selectedPop.campaignBurstId !== this.selectedBurst.id)){
                this.burstHasChanged = true
            }
            // Check Screen has changed
            if(this.selectedScreen !== null && (this.selectedPop.campaignBurstScreenId !== this.selectedScreen.id)){
                this.screenHasChanged = true
            }
        },
        async clearEditData(){
            // Reset Modal values
            this.selectedUploadCategory = null
            this.selectedUploadValue = null
            this.selectedDate = null
            // Reset Campaign data
            this.screensByBurst = []
            this.burstsByCampaign = []
            this.selectedCampaign = null
            this.selectedBurst = null
            this.selectedScreen = null
            // Reset selected dot
            this.selectedActiveDot = null
            this.deleteDot = false
            this.dotSelected = false
            // Close dialog
            this.editPopDialog = false
            // Get data after edit
            this.selectedPop = this.data
        },
        async uploadTagtoPop(tags){
            const selectedTag = tags.shift()
            if(selectedTag){
                try{
                    const popTag = {
                        proofOfPlayId: this.selectedPop.id,
                        categoryId: selectedTag.id,
                        categoryShortName: selectedTag.shortName,
                        value: selectedTag.value
                    }
                    const addTagToPop = await CampaignController.addPoPTag(popTag)
                    if(!addTagToPop){return}
                    const dot = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    addTagToPop.data['dot'] = dot[0]
                    addTagToPop.data.media.tags = addTagToPop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(addTagToPop)
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully added tags to PoP.',
                    )
                    await this.uploadTagtoPop(tags)
                } catch(err){
                    await this.uploadTagtoPop(tags)
                    this.$root.$emit(
                        'snackbarError',
                        ''+ err.response.data.message,
                    )
                }
            }
        },
        async deleteFilteredTags(tags){
            const nextTag = tags.shift()

            if(nextTag){
                try{
                    const deletePop = await CampaignController.deletePoPTag(nextTag.mediaTagId)
                    if(!deletePop){return}
                    const dot = deletePop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    deletePop.data['dot'] = dot[0]
                    deletePop.data.media.tags = deletePop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(deletePop)
                    this.$root.$emit(
                        'snackbarSuccess',
                        'Successfully delete tag from PoP.',
                    )
                    this.deleteFilteredTags(tags)
                } catch(err){
                    this.deleteFilteredTags(tags)
                    this.$root.$emit(
                        'snackbarError',
                        ''+ err.response.data.message,
                    )
                }
            }
        },
        async uploadDotToPop(){
            const dotArr = []
            const dotTag = {
                proofOfPlayId: this.selectedPop.id,
                id: 5,
                shortName: "errordot",
                value: this.selectedActiveDot.name+" "+this.selectedActiveDot.color
            }
            dotArr.push(dotTag)
            await this.uploadTagtoPop(dotArr)
        },
        async editPopConfirm(){
            // Check Campaign has changed
            await this.isEditChanged()
            // Check if campaign data has been changed
                let updatedPop = {
                    proofOfPlayId: this.data.id,
                    campaignId: this.selectedCampaign !== null ? this.selectedCampaign.id : 0,
                    campaignBurstId: this.selectedBurst !== null ? this.selectedBurst.id : 0,
                    campaignBurstScreenId: this.selectedScreen !== null ? this.selectedScreen.id : 0
                }
                try{
                    const updatePop = await CampaignController.updatePoP(updatedPop)
                    if(!updatePop){return}
                    const dot = updatePop.data.media.tags.filter(obj => {return obj.categoryName === "DoT"})
                    updatePop.data['dot'] = dot[0]
                    updatePop.data.media.tags = updatePop.data.media.tags.filter(obj => {return obj.categoryName !== "DoT"})
                    await this.updatePops(updatePop)
                    this.$root.$emit(
                            'snackbarSuccess',
                            'Successfully edited PoP.',
                    )
                } catch(err){
                    console.log(err)
                }
            // Check if any existing tag has been removed
            if(this.tagsToRemove.length !== 0){
                await this.deleteFilteredTags(this.tagsToRemove)
            }
            // Check if any new tags have been added
            if(this.tagsToAdd.length !== 0){
                await this.uploadTagtoPop(this.tagsToAdd)
            }
            // Upload Dot tag if does not exist
            if(this.selectedPop.dot === undefined && this.dotSelected) {
                await this.uploadDotToPop()
            }
            // Delete + upload dot tag if exist and changed
            if (this.selectedPop.dot !== undefined && this.dotSelected) {
                await this.deleteDots()
                await this.uploadDotToPop()
            }
            // Check if remove dot selected
            if(this.deleteDot) {
                await this.deleteDots()
            }
            await this.clearEditData()
        },
        // Delete dot if present in pop data
        async deleteDots() {
            let dotArr = []
            let dotTag = {
                mediaTagId: this.selectedPop.dot.mediaTagId,
            }
            dotArr.push(dotTag)
            await this.deleteFilteredTags(dotArr)
        },
        // Add tags to upload list+display
        async addTagToUpload(tag){
            this.selectedPop.media.tags.push(tag)
            this.tagsToAdd.push(tag)
        },
        async showEditDialog(){

            this.$nextTick()
            this.editPopDialog = true
            this.uploadPopData = this.data
            this.isDate()
            // Pre-populate campaign if exists
            const currCampaign = this.selectedPop.categoryId === 0 ? null : this.allCampaigns.find(obj => {return obj.id === this.selectedPop.campaignId})
            if(currCampaign != null){
                this.selectedCampaign = currCampaign
                this.getInitialBurst()
            }
            // Pre-populate dot if exists
            const dot = this.selectedPop.dot === undefined ? null : this.dots.find(obj => {return obj.color === this.selectedPop.dot.value.split(" ")[1]})
            if(dot != null)
                this.selectedActiveDot = dot
        }
    },

}
</script>

<style scoped>
    .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23px;
        width: 23px;
        border-radius: 50%;
    }

    .dot_checkbox {
        font-size: 16px;
        color: #4E5D53;
    }
</style>