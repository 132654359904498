var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.Permissions.proofOfPlayEdit),expression:"$store.state.Permissions.proofOfPlayEdit"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"0%","margin-right":"-0.5em"},attrs:{"color":"white darken-3","icon":""},on:{"click":function($event){return _vm.showEditDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"1.9em"}},[_vm._v("mdi-cog")])],1)]}}])},[_c('span',[_vm._v("Edit PoP")])]),_c('v-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.editPopDialog),expression:"editPopDialog"}],attrs:{"persistent":"","width":"700"},model:{value:(_vm.editPopDialog),callback:function ($$v) {_vm.editPopDialog=$$v},expression:"editPopDialog"}},[_c('v-card',{staticClass:"overflow-x-hidden",attrs:{"id":"editPopDialog"}},[_c('v-card-title',{staticClass:"white--text primary"},[_vm._v(" Edit Proof of Play - FP#"+_vm._s(_vm.data.id)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"my-n2 px-4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Select Campaign")])])]),_c('v-col',{staticClass:"px-2 mb-n8",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.Campaigns,"item-text":'name',"label":"Campaign","outlined":"","return-object":"","clearable":""},on:{"change":function($event){return _vm.getCampaignData()},"click:clear":function($event){_vm.selectedCampaign = null
                                _vm.selectedBurst = null
                                _vm.selectedScreen = null}},model:{value:(_vm.selectedCampaign),callback:function ($$v) {_vm.selectedCampaign=$$v},expression:"selectedCampaign"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"disabled":!_vm.selectedCampaign,"items":_vm.burstsByCampaign,"item-text":'name',"label":"Burst","outlined":"","return-object":"","clearable":""},on:{"change":function($event){return _vm.changeBurst()},"click:clear":function($event){_vm.selectedBurst = null
                                _vm.selectedScreen = null}},model:{value:(_vm.selectedBurst),callback:function ($$v) {_vm.selectedBurst=$$v},expression:"selectedBurst"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{key:"id",attrs:{"disabled":!_vm.selectedBurst,"items":_vm.SortScreens,"value":"id","item-text":function (item) { return ((item.screen.mediaOwner.friendlyName) + " - " + (item.screen.name)); },"label":"Screen","outlined":"","return-object":"","clearable":""},on:{"click:clear":function($event){_vm.selectedScreen = null}},model:{value:(_vm.selectedScreen),callback:function ($$v) {_vm.selectedScreen=$$v},expression:"selectedScreen"}})],1)],1),(_vm.selectedPop.media.tags.find(function (obj) {return obj.shortName === 'datetimetaken'}) === undefined)?_c('v-row',{staticClass:"mt-n6 px-4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Choose date & time")])])]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.selectedDate,"outlined":"","append-icon":_vm.selectedDate ? '' : 'mdi-calendar',"hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.selectedDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,1834827338)},[_c('v-date-picker',{attrs:{"tabindex":"0","no-title":"","scrollable":""},on:{"input":function($event){return _vm.addTagToUpload({    
                                        categoryId: 4, 
                                        shortName: 'datetimetaken',
                                        category: 'Date/Time Taken', 
                                        value: _vm.selectedDate
                                    })}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-7 px-4"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("General Tag(s)")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"26px","height":"26px","margin-top":"0.25em"},attrs:{"disabled":_vm.addTag === null,"fab":"","color":"primary"},on:{"click":function($event){_vm.addTagToUpload({  
                                                    categoryId: _vm.selectedUploadCategory.id, 
                                                    shortName: _vm.selectedUploadCategory.shortName,
                                                    category: _vm.selectedUploadCategory.name, 
                                                    value: _vm.addTag
                                                })
                                                _vm.addTag = null}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add tag")])])],1)])]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.Categories,"item-value":"id","item-text":"name","label":"Tag Category","outlined":"","return-object":""},on:{"change":function($event){_vm.addTag = null}},model:{value:(_vm.selectedUploadCategory),callback:function ($$v) {_vm.selectedUploadCategory=$$v},expression:"selectedUploadCategory"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"disabled":_vm.selectedUploadCategory === null,"items":_vm.Values,"item-text":'value',"label":"Add Tag","clearable":"","outlined":""},model:{value:(_vm.addTag),callback:function ($$v) {_vm.addTag=$$v},expression:"addTag"}})],1),_c('v-col',{staticClass:"mt-n4 mb-2 px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-n3 text-center"},_vm._l((_vm.data.media.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"mx-2 mt-4 text-uppercase",style:('background: none; border: 1px solid '+ _vm.addColorToTag(tag)),attrs:{"close":""},on:{"click:close":function($event){_vm.tagsToRemove.push(tag)
                                    _vm.selectedPop.media.tags.splice(index, 1)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(tag.value.toUpperCase())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s((tag.categoryName)))])])],1)}),1)])],1),(_vm.selectedPop.dot != undefined)?_c('v-row',{staticClass:"mt-5 px-4"},[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"disabled":_vm.dotSelected,"label":"Remove existing dot?","color":"red","hide-details":""},on:{"click":function($event){_vm.deleteDot = !_vm.deleteDot
                                    _vm.dotSelected = false}}})],1)])],1):_vm._e(),_c('v-row',{class:_vm.dotSelected ? 'mt-7 px-4' : 'mt-5 px-4'},[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"disabled":_vm.deleteDot,"label":"Diagnostic Overlay Tag?","color":"red","hide-details":""},on:{"click":function($event){_vm.deleteDot = false}},model:{value:(_vm.dotSelected),callback:function ($$v) {_vm.dotSelected=$$v},expression:"dotSelected"}})],1)]),(_vm.dotSelected)?_c('v-col',{staticClass:"px-2 ml-1 d-flex",attrs:{"cols":"6"}},_vm._l((_vm.dots),function(dot,index){return _c('div',{key:index,staticClass:"dot mr-2",style:({background: dot.color}),on:{"click":function($event){_vm.selectedActiveDot = dot}}},[(_vm.selectedActiveDot && _vm.selectedActiveDot.color === dot.color)?_c('span',{staticClass:"dot_checkbox gray--text",attrs:{"outlined":""}},[_vm._v("✔")]):_vm._e()])}),0):_vm._e()],1),_c('v-row',{staticClass:"my-3 mt-7",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-6",attrs:{"disabled":_vm.addTag !== null,"color":"primary"},on:{"click":function($event){return _vm.editPopConfirm()}}},[_vm._v(" Confirm ")]),_c('v-btn',{staticClass:"mx-6",attrs:{"color":"red"},on:{"click":function($event){_vm.editPopDialog=false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }