<template>
    <v-sheet>
        <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :color="hover ? 'primary' : 'grey'"
                            class="mr-n1"
                            icon
                            @click.stop="drawer = !drawer"
                        >
                            <v-icon style="font-size: 1.8em">mdi-timeline-text-outline</v-icon>
                        </v-btn>
                    </v-hover>
                </template>
                <span>Timeline</span>
        </v-tooltip>
        <v-navigation-drawer
            v-model="drawer"
            width="70%"
            absolute
            temporary
            right
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="my-2 mx-1 text-center">
                        <span class="subtitle-2">Timeline and comments for FP{{ selectedPop.id }}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-timeline
                dense
                clipped
                class="mx-5"
            >
                <v-timeline-item
                    fill-dot
                    class="white--text mb-4"
                    color="primary"
                    medium
                >
                    <template v-slot:icon>
                        <h5>DH</h5>
                    </template>
                    <v-text-field
                        v-model="input"
                        hide-details
                        flat
                        label="Leave a comment..."
                        solo
                        @keydown.enter="comment"
                        class="py-4"
                    >
                        <template v-slot:append>
                            <v-btn
                            class="my-2"
                            depressed
                            @click="comment"
                            >
                            Post
                            </v-btn>
                        </template>
                    </v-text-field>
                    </v-timeline-item>
                    <v-slide-x-transition
                        group
                    >
                        <v-timeline-item
                            v-for="event in timeline"
                            :key="event.id"
                            fill-dot
                            class="mb-4 dot"
                            color="grey darken-1"
                            medium
                        >
                            <template v-slot:icon>
                                <h5>{{ event.inititals }}</h5>
                            </template>
                            <v-card>
                                <v-card-text class="px-6 body-2">{{ event.text }}</v-card-text>
                            </v-card>
                        </v-timeline-item>
                </v-slide-x-transition>
            </v-timeline>
        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
    import UserController from '@/services/controllers/User'
    export default {
        data () {
            return {
                drawer: null,
                events: [],
                input: null,
                title: null,
                nonce: 0,
                selectedPop: this.data,
                userData: '',
            }
        },
        props: ['data'],
        computed: {
            timeline () {
                return this.events.slice().reverse()
            },
        },
        mounted() {
            this.userDetails()
        },
        methods: {
            async userDetails () {
                await UserController.whoAmI().then((res) => {
                    this.userdata = res.data.contactCard.firstName.charAt(0)+res.data.contactCard.surname.charAt(0)
                })
            },
            comment () {
                let time = new Date()
                const date = time.toDateString();
                const hours = time.toLocaleTimeString()
                time = date + ' ' + hours
                this.events.push({
                    id: this.nonce++,
                    inititals: this.userdata,
                    text: this.input,
                    time: time,
                })
                this.input = null
            },
        },
    }
</script>