<template>
    <div class="mr-3" v-if="$store.state.Permissions.tagGlobalEdit">
        <!-- Global Edit Button/Icon-->
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    @click="editGlobalTags = true"
                    fab color="primary" 
                    style="width: 26px; height: 26px;"
                    >
                        <v-icon small class="">mdi-pencil-outline</v-icon>
                    </v-btn>
                </template>
            <span>Edit global tags</span>
        </v-tooltip>
        <!-- Edit Dialog -->
        <v-dialog
            v-model="editGlobalTags"
            persistent
            width="700"
        >
            <v-card id="editPopDialog">
                <v-card-title class="white--text primary">
                    Edit Global Tags
                </v-card-title>
                <!-- Upload buttons -->
                <v-card-text>
                     <!-- Tags -->
                     <v-row>
                        <v-col cols="12" class="px-2">
                            <div class="d-flex justify-space-between">
                                <h4>General Tag(s)</h4>
                                <div>
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            :disabled="!addTag"
                                            @click="updateMergeList({
                                                categoryId: selectedUploadCategory.id, 
                                                shortName: selectedUploadCategory.shortName,
                                                categoryName: selectedUploadCategory.name, 
                                                value: addTag.value,
                                                tagId: addTag.id
                                                })
                                                addTag = null
                                            "
                                            fab color="red darken-3" 
                                            class="mt-1.5 mr-3"
                                            style="width: 26px; height: 26px;"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Remove tag</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            :disabled="!addTag"
                                            @click="updateAddtags({
                                                categoryId: selectedUploadCategory.id, 
                                                shortName: selectedUploadCategory.shortName,
                                                categoryName: selectedUploadCategory.name, 
                                                value: addTag.value,
                                                tagId: addTag.id
                                                })
                                                addTag = null
                                            "
                                            fab color="primary" 
                                            class="mt-1.5 mr-1"
                                            style="width: 26px; height: 26px;"
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add tag</span>
                                </v-tooltip>
                            </div>
                            </div>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-select
                                v-model="selectedUploadCategory"
                                :items="Categories"
                                item-value="id"
                                item-text="name"
                                label="Tag Category"
                                outlined
                                return-object
                                @change="
                                    addTag = null
                                "
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="px-2">
                            <v-combobox
                                :disabled="!selectedUploadCategory"
                                :items="Values"
                                :item-text="'value'"
                                v-model="addTag"
                                label="Add Tag"
                                clearable
                                outlined
                            ></v-combobox>
                        </v-col>
                        </v-row>
                        <v-row class="px-2 mb-2">
                            <h4>List of tags to merge: </h4>
                            <v-col cols="auto" class="d-flex  align-end flex-wrap fluid ma-0 pa-0"
                            style="width: 100%"
                            >
                                <v-chip
                                    v-for="(tag, index) in tagsMerge" :key="index"
                                    class="mt-1 mb-3 mx-1 text-capitalize"
                                    :style="'height: 25px; background: none; border: 1px solid '+addColorToTag(tag)"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    {{tag.value}}
                                                </span>
                                        </template>
                                        <span>{{(tag.categoryName)}}</span>
                                    </v-tooltip>
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row class="px-2 my-3">
                            <h4>List of tags to add: </h4>
                            <v-col cols="auto" class="d-flex  align-end flex-wrap fluid ma-0 pa-0"
                            style="width: 100%"
                            >
                                <v-chip
                                    v-for="(tag, index) in tagsAdd" :key="index"
                                    class="mt-1 mb-3 mx-1 text-capitalize"
                                    :style="'height: 25px; background: none; border: 1px solid '+addColorToTag(tag)"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    {{tag.value}}
                                                </span>
                                        </template>
                                        <span>{{(tag.category)}}</span>
                                    </v-tooltip>
                                </v-chip>
                            </v-col>
                        </v-row>
                        <!-- Upload buttons -->
                        <v-row
                            justify="center"
                            class="my-3"
                        >
                            <!-- Confirm upload button -->
                            <v-btn
                                :disabled='addTag !== null'
                                class="mx-6"
                                color="primary"
                                @click="confirmEdit"
                            >
                                Confirm
                            </v-btn>
                            <!-- Decline upload button -->
                            <v-btn
                                class="mx-6"
                                color="red"
                                @click="editGlobalTags=false"
                            >
                                Cancel
                            </v-btn>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CampaignController from "@/services/controllers/Campaign"
export default {
    name: 'EditGlobal',
    props: ['tagCategs'],
    inject: ['addColorToTag'],
    data() {
        return {
            pop: null,
            editGlobalTags: false,
            selectedUploadCategory: null,
            addTag: null,
            tags: this.tagCategs,
            tagsAdd: [],
            tagsMerge: []
        }
    },
    computed: {
        Categories() {
            return this.tags.map((data) => {
                return data
            })
        },
        Values() {
            let values = []
            if(this.selectedUploadCategory) {
                this.tags.forEach((item) => {
                    if(item.shortName === this.selectedUploadCategory.shortName)
                    item.tags.forEach((tag)=>{
                        values.push({id: tag.tagId, value: tag.value})
                    })
                })
            }
            return values
        }
    },
    methods: {
        async updateAddtags(tag) {
            await this.addColorToTag(tag)
            this.tagsAdd.push(tag) 
        },
        async updateMergeList(tag) {
            await this.addColorToTag(tag)
            this.tagsMerge.push(tag) 
        },
        async mergeTags(){
            const nextTag = this.tagsMerge.shift()
            if(nextTag){
                try{
                    const removeTag = await CampaignController.deletePoPTag(nextTag.tagId)
                }catch {

                }
            }
        },
        async confirmEdit(){
            if(this.tagsMerge.length !== 0){
                await this.mergeTags()
            }
        }
    }
}
</script>