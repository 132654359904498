<template>
    <v-row id="tagEditDIalog" >
        <!-- Header -->
        <v-row class="my-4 mx-2 d-flex justify-space-between align-center">
            <h2>Edit tags page</h2>
            <v-btn
                :color="mergeMode ? 'primary' : ''"
                @click="mergeMode = !mergeMode"
            >
                Merge
            </v-btn>
        </v-row>
        <!-- Table -->
        <v-expansion-panels multiple>
            <!-- Table Header -->
            <v-expansion-panel>
            <v-expansion-panel-header hide-actions disabled style="cursor:default">
                <v-row class="d-flex justify-space-between align-center text-center">
                    <h5 style="flex:0.03" class="text-left">#</h5>
                    <h5 style="flex:0.25" class="text-left">Category Name</h5>
                    <h5 style="flex:0.25" class="text-left">Shortname</h5>
                    <h5 style="flex:0.03; margin-left:0.3em"># Tags</h5>
                    <h5 style="flex:0.07">Actions</h5>
                </v-row>
            </v-expansion-panel-header>
            </v-expansion-panel>
            <!-- Table Row -->
            <v-expansion-panel
                v-for="categ in tagList"
                :key="categ.id"
            >
            <v-expansion-panel-header hide-actions>
                <v-row class="d-flex justify-space-between align-center text-center">
                    <h5 style="flex:0.03" class="text-left">{{ categ.id }}</h5>
                    <h5 style="flex:0.25" class="text-left">{{ categ.name }}</h5>
                    <h5 style="flex:0.25" class="text-left">{{ categ.shortName }}</h5>
                    <h5 style="flex:0.03">{{ categ.tags.length }}</h5>
                    <v-btn 
                        small 
                        text 
                        class="pa-0" 
                        style="flex:0.07; min-width: 0"
                        @click.native.stop
                        @click="
                            addTagUpload(categ, {
                                    categoryId: categ.id, 
                                    value: addTag.parent_id[categ.id]
                                }) 
                            ">
                        Add
                    </v-btn>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-col cols="12" class="px-2">
                    <v-combobox
                        :search-input.sync="addTag.parent_id[categ.id]"
                        dense
                        label="Add Tag"
                        clearable
                        outlined
                        append-icon=""
                    ></v-combobox>
                </v-col>
                <v-col cols="12" class="mt-n4 mb-2 px-2">
                    <div class="mt-n3 text-center">
                        <v-chip
                            v-for="(tag, index) in categ.tags" :key="index"
                            class="mx-2 mt-4 text-uppercase"
                            :style="'background: none; border: 1px solid '+categ.colour"
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{tag.value}}
                                        </span>
                                    </template>
                                    <span>Select merge mode</span>
                                </v-tooltip>
                        </v-chip>
                    </div>
                </v-col>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row class="d-flex justify-center mt-5 mb-2">
            <v-btn @click="submit">
                click me baby one more time
            </v-btn>
        </v-row>
    </v-row>
</template>
<script>
import CampaignController from "@/services/controllers/Campaign"
export default {
    props: ['masterTagCateg', 'tagCateg'],
    inject: ['dynamicSort', 'addColorToTag'],
    name: 'EditTags',
    data() {
        return {
            tagList: this.tagCateg.sort(this.dynamicSort('id')),
            initialList: this.masterTagCateg,
            listTags: [],
            listTagsDirty: [],
            mergeMode: false,
            selectedUploadCategory: null,
            addTag: {
                parent_id: []
            },
            listTagsAdd: [],
            listTagsRemove: [],
        }
    },
    beforeMount(){
        // Append original tags to a list
        this.initialList.forEach(categ => {
            if(categ.tags.length > 0){
                categ.tags.forEach(tag =>{
                    this.listTags.push(tag)
                })
            }
        })
    },
    methods: {
        addTagUpload(categ, tag) {
            this.listTagsAdd.push(tag)
            this.tagList[categ.id-1].tags.push(tag)
        },
        deleteTags(){
            const nextTag = this.listTagsAdd.shift()
            if(nextTag){
                // api call
                CampaignController.addTag(nextTag).then(()=>{
                    this.deleteTags()
                }).catch((err) =>{
                    this.deleteTags()
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })
            } else{
                this.$root.$emit('snackbarSuccess', 'Tags deleted successfully.')
            }
        },
        uploadTags(){
            const nextTag = this.listTagsAdd.shift()
            if(nextTag){
                // api call
                CampaignController.addTag(nextTag).then(()=>{
                    this.uploadTags()
                }).catch((err) =>{
                    this.uploadTags()
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })
            } else{
                //this.deleteTags()
                this.$root.$emit('snackbarSuccess', 'Tags uploaded successfully.')
            }
        },
        submit(){
            // Append modified tags to a list
            this.tagList.forEach(categ => {
                if(categ.tags.length > 0){
                    categ.tags.forEach(tag =>{
                        this.listTagsDirty.push(tag)
                    })
                }
            })
            // Check if tags been added/removed/merged
            if(this.listTags.length === this.listTagsDirty.length){
                this.$root.$emit('snackbarError', 'No changes detected.')
            } else { // Execute add/delete tags 
                this.uploadTags()
            }
        },
    },
}
</script>