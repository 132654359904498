<template>
    <v-row style="width: 100%; height: 500px" class="mt-10">
        <l-map
            ref="popMapElement"
            :zoom="zoomLevel"
            :center="centerLocation" 
            style="z-index:1"
        >
            <l-tile-layer
                :url="url"
                :attribution="attribution"
            />
                <l-marker
                    v-for="(pop, index) in locationtableData"
                    :key="index"
                    :lat-lng="[pop.media.tags.find(x => x.shortName === 'latlong').value.split(',')[0], pop.media.tags.find(x => x.shortName === 'latlong').value.split(',')[1]]"
                >
                    <l-popup
                        :lat-lng="[pop.media.tags.find(x => x.shortName === 'latlong').value.split(',')[0], pop.media.tags.find(x => x.shortName === 'latlong').value.split(',')[1]]"
                    >
                    <span class="white--text d-flex justify-center">{{ pop.popName }}</span>
                    <v-row class="ma-0 pt-1 px-3" :style="{ minHeight: 'calc(100% - 1rem)' }">
                                <v-col cols="auto" class="d-flex justify-center align-center pb-3" style="width: 100%;"> 
                                    <div
                                        v-if="
                                            pop.media.url.split('.').pop() === 'mp4' ||
                                            pop.media.url.split('.').pop() === 'webm' ||
                                            pop.media.url.split('.').pop() === 'mov'
                                        "
                                        class="d-flex justify-center align-center"
                                        style="width: 100%; object-fit: cover; cursor: pointer"
                                    >  
                                        <video
                                            :key="pop.media.url"
                                            style="
                                                max-height: 400px;
                                                max-width: 330px;
                                                display: block;
                                            "
                                            controls
                                            >
                                            <source
                                                :src="pop.media.url"
                                                controls
                                            >
                                        </video>
                                    </div>
                                    <div v-else style="width: 100%">
                                        <v-img
                                            contain 
                                            :lazy-src="pop.media.urlThumb"
                                            :src="pop.media.urlThumb"
                                            :style="{ position: 'relative', maxHeight: '200px', cursor: 'pointer' }"
                                            @click="openImage(pop.media.url)"
                                        >
                                            <!-- Display download btn -->
                                            <div
                                                :style="
                                                    ($vuetify.theme.dark)
                                                    ? {
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px'
                                                    }
                                                    : {
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px'
                                                    }
                                                "
                                            >
                                                <v-btn 
                                                    fab 
                                                    small
                                                    @click.prevent="downloadItem({media: pop.media})"
                                                ><v-icon>mdi-download</v-icon></v-btn>
                                            </div>
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                    </div>
                                </v-col>
                                <!-- Display Tags -->
                                <v-expansion-panels flat :value="1">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <span class="text-center" style="opacity:0.35; margin-bottom: 0.6%;">Toggle Tag View</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <!--
                                            <div v-if="pop.media.tags.length <= 1" class="text-center" style="opacity: 0.5">No tags to display</div>
                                            -->
                                            <v-col cols="auto" class="d-flex justify-center my-n5 pb-6 flex-wrap" style="gap:1em;">
                                                <v-chip
                                                    v-for="(tag, index) in pop.media.tags" :key="index"
                                                    class="text-capitalize mt-3 flex-grow-0 flex-shrink-0"
                                                    :style="'height: 25px; background: none; border: 1px solid '+addColorToTag(tag)"
                                                    @click="searchTag(tag)"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <span 
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >{{tag.value.toUpperCase()}}</span>
                                                        </template>
                                                        <span>{{(tag.categoryName)}}</span>
                                                    </v-tooltip>
                                                </v-chip>
                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-row>
                    </l-popup>
                </l-marker>
        </l-map>
    </v-row>
</template>
<script>
import L, { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
import axios from 'axios'
export default {
    inject: ['openImage', 'addColorToTag', 'searchTag'],
    name: 'MapView',
    props: ['locationtableData'],
    components: {
      latLng,
      L,
      LIcon,
      LMap,
      LPopup,
      LTileLayer,
      LMarker,
    },
    data: () => ({
        // Zoom / Center
        zoomLevel:10,
        centerLocation: [51.5, -0.1],
        // Map Style
        url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        // Marker locations
        //marker: L.latLng(47.41322, -1.219482),
        markerLatLng: [51.52463861618491, -0.11485325878846236],
    }),
    created() {
    },
    methods: {
        async downloadItem({ media }) {
            let downloadUrl
            let downloadFileName
            let mimeType
            downloadUrl = media.url
            downloadFileName = media.originalFileName
            mimeType = media.mimeType
            let cachebuster = Math.round(new Date().getTime() / 1000);
            const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
            const blob = new Blob([response.data], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = downloadFileName;
            link.click();
            URL.revokeObjectURL(link.href);
        },
    },
}
</script>
<style scoped>
  *::v-deep .leaflet-popup-content-wrapper {
    background-color: rgb(30,30,30) !important;
  }
</style>