<template>
    <v-col cols="auto" class="d-flex justify-center align-center ma-0 pa-0 pb-3" style="width: 100%;"> 
        <div ref="heatmapContainer" >
        <img
            contain 
            :src="media.urlThumb"
            :style="{ position: 'relative', maxHeight: '400px', cursor: 'pointer' }"
            ref="heatmapImage"
            alt="Heatmap Image"
        />
        </div>
    </v-col>
</template>
<script>
import heatmap from "heatmapjs";
import { createWorker } from 'tesseract.js';

export default {
    name: 'Heatmap',
    props: {
        pop: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            heatmap: [],
            heatmapInstance: null,
            media: this.pop.media,
        }
    },
    mounted() {
        //console.log('From heatmap component')
        //console.log(this.pop)
        this.createHeatmap()
    },
    methods: {
    async createHeatmap() {
      this.heatmapInstance = heatmap.create({
        container: this.$refs.heatmapContainer,
        radius: 10 // Adjust this value according to your preference
      });

      const textResults = await this.detectTextRegions()
      const heatmapData = this.generateHeatmapData(textResults);

      this.heatmapInstance.setData({
        data: heatmapData,
        max: 2 // Adjust this value based on your data
      });
      this.$nextTick()
      //console.log('Point reached')
    },
    async detectTextRegions() {
      const worker = await createWorker({
        // rewrite langPath: path.join(__dirname, '..', 'lang-data') to incldue the file from src/assets/lang
        //langPath: path.join(__dirname, '', 'lang-data'),
        logger: m => console.log(m)
      });
      await worker.loadLanguage('eng');
      await worker.initialize('eng');
      const text = await worker.recognize(this.media.urlThumb);
      //console.log(text);
      const textRegions = text.data.words.map(word => word.bbox);
      //console.log('text regions')
      //console.log(textRegions)
      return textRegions;
    },
    generateHeatmapData(textRegions) {
      const maxWidth = this.media.width;
      const maxHeight = this.media.height;
      const heatmapData = [];

      textRegions.forEach(region => {
        const x0 = region.x0;
        const x1 = region.x1;
        const y0 = region.y0;
        const y1 = region.y1;

        // Calculate the center of the text region
        const centerX = (x0 + x1) / 2;
        const centerY = (y0 + y1) / 2;

        for (let y = y0; y < y1; y++) {
          for (let x = x0; x < x1; x++) {
            // Calculate the distance from the center of the text region
            const distanceX = (x - centerX) / maxWidth;
            const distanceY = (y - centerY) / maxHeight;
            const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
           // console.log('distance')
           // console.log(distance)

            // Calculate intensity based on distance, with lower intensity
            const intensity = (1 / (1 + distance / 0.05)); // Adjust the divisor for intensity range
           // console.log('intensity')
           // console.log(intensity)
            heatmapData.push({ x, y, value: intensity });
          }
        }
      });

      return heatmapData;
    }
  }
};
</script>